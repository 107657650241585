import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { makeAutoObservable } from 'mobx';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserPreferenceStore {
  languageKey: string = 'en';

  constructor(private translateServic: TranslateService) {
    makeAutoObservable(this);
  }

  loadUserPreferences(): Observable<void> {
    return of(void 0).pipe(delay(1000));
  }

  async initialize(): Promise<void> {
    try {
      await this.loadUserPreferences().toPromise();
      this.translateServic.addLangs(['en', 'ar']);
      this.languageKey = 'en';
      this.translateServic.setDefaultLang(this.languageKey);
    } catch (error) {
      console.error('Failed to load user preferences', error);
    }
  }
}
